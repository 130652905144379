body {
  color: #3d3d3d;
  --mdc-theme-primary: #007f8b;
  --mdc-theme-on-primary: #f1f3f4;
  margin: 2em;
  font-family: roboto;
}

h1 {
  color: #007f8b;
}

h2 {
  clear: both;
}

em {
  font-weight: bold;
}

video {
  clear: both;
  display: block;
  transform: rotateY(180deg);
}

section {
  opacity: 1;
  transition: opacity .5s ease-in-out;
}

header, footer {
  clear: both;
}

.removed {
  display: none;
}

.invisible {
  opacity: .2;
}

.note {
  font-size: 130%;
  font-style: italic;
}

.videoView, .detectOnClick {
  float: left;
  cursor: pointer;
  width: 48%;
  margin: 2% 1%;
  position: relative;
}

.videoView p, .detectOnClick p {
  color: #fff;
  z-index: 2;
  background-color: #007f8b;
  border: 1px dashed #ffffffb3;
  margin: 0;
  padding: 5px;
  font-size: 12px;
  position: absolute;
}

.highlighter {
  z-index: 1;
  background: #00ff0040;
  border: 1px dashed #fff;
  position: absolute;
}

.canvas {
  z-index: 1;
  pointer-events: none;
  position: absolute;
}

.output_canvas {
  transform: rotateY(180deg);
}

.detectOnClick {
  z-index: 0;
}

.detectOnClick img {
  width: 100%;
}

/*# sourceMappingURL=index.a40fbbe4.css.map */
